import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

const Contactussection1 = () => {
  return (
    <>
      <div className="contact-area section-padding  bg-white my-14">
        <div className="xl:container mx-auto my-6 p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="col-span-1">
              <div className="contact-text">
                <h2 className="text-3xl font-semibold text-black">
                  Are You Interested in Contacting Us?
                </h2>
                <p className="text-black mt-4">
                Your Gateway to Hassle-Free Registrations
                </p>
                <div className="contact-sub mt-8">
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-black text-2xl" />
                    <div className="contact-c ml-4">
                      <h4 className="text-lg font-semibold text-black">
                        Our Location
                      </h4>
                      <a
                        href="https://maps.google.com?q=WG26+9HG,Channasandra,Srinivaspura,Bengaluru,Karnataka560098"
                        className="text-black"
                        target="_blank"
                        rel="noopener noreferrer"
                      > <p className="font-bold">1) Head Office:</p>  Registration Guru  #537/B, Shubhaprada 11th block. B.S.K 6th Stage 
                      Srinivaspura, Uttarahalli Road, Bengaluru - 560060
                      <p className="font-bold mt-2">2) Branch Office:</p>208, 17th E Main Rd, KHB Colony, 5th Block, Koramangala, Bengaluru, Karnataka 560034
                      </a>
                    </div>
                  </div>
                </div>
                <div className="contact-sub mt-4">
                  <div className="flex items-center">
                    <FaPhone className="text-black text-2xl" />
                    <div className="contact-c ml-4">
                      <h4 className="text-lg font-semibold text-black">
                        Phone
                      </h4>
                      <span className="text-black">
                      
                   <a href="tel:+ 8904917771" className="text-black">
                        8904917771  
                        </a><br></br>
                        <a href="tel:+7892929155" className="text-black">
                          7892929155
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="contact-sub mt-4">
                  <div className="flex items-center">
                    <FaEnvelope className="text-black text-2xl" />
                    <div className="contact-c ml-4">
                      <h4 className="text-lg font-semibold text-black">
                        Email
                      </h4>
                      <span className="text-black">
                        {" "}
                        <a
                          href="mailto:info@registrationguru.org"
                          className="text-black"
                        >
                          info@registrationguru.org
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="contact-content">
                <div className="contact-form">
                  <form>
                    <div className="contact-form form-style grid grid-cols-2 md:grid-cols-2 gap-4">
                      <div className="col-span-1">
                        <input
                          type="text"
                          placeholder="Your Name*"
                          id="fname"
                          name="name"
                       
                          className="border border-black py-2 px-4 rounded-md w-full"
                        />
                      </div>
                      <div className="col-span-1">
                        <input
                          type="text"
                          placeholder="Phone"
                          id="number"
                          name="number"
                       
                          className="border-black border py-2 px-4 rounded-md w-full"
                        />
                      </div>
                      <div className="col-span-1">
                        <input
                          type="email"
                          placeholder="Your Email"
                          id="email"
                          name="email"
                       
                          className="border-black border py-2 px-4 rounded-md w-full"
                        />
                      </div>
                      <div className="col-span-1">
                        <select
                          className="form-select border border-black py-2 px-4 rounded-md w-full"
                          name="events"
                        >
                          
                          <option value="1">For Registration</option>
                          <option value="2">For Divorce</option>
                          <option value="3">For Cyber Crime</option>
                          <option value="4">Criminal Matter</option>
                          <option value="5">Maintenance</option>
                          <option value="5">Child Custody</option>
                          <option value="5">Bank Account De-freezing</option>
                          <option value="5">Anticipatory Bail</option>
                          <option value="5">Regular Bail</option>
                          <option value="5">Judicial Seperation</option>
                          <option value="5">Restitution of Conjugal Rigth(RCR)</option>
                          
                          

                        </select>
                      </div>
                      <div className="col-span-2">
                        <textarea
                          className="contact-textarea border border-black py-2 px-4 rounded-md w-full"
                          placeholder="Message"
                          name="notes"
                        ></textarea>
                      </div>
                      <div className="col-span-2">
                        <button
                          type="submit"
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                        >
                          Appointment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        <div style={{ width: "100%", textAlign: "center" }}>
  <iframe
    width="50%"
    height="500"
    frameborder="0"
    title="Registration Guru"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=Registration%20Guru%20WG26+9HG,%20Channasandra,%20Srinivaspura,%20Bengaluru,%20Karnataka%20560098+(Registration%20Guru)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    style={{ margin: "auto" }}
  >
    <a href="https://www.maps.ie/population/">Registration Guru</a>
  </iframe>
</div>

        </div>
      </div>
    </>
  )
}

export default Contactussection1