import React from 'react';
import imgbanner from "../Assets/parntershipbanner.jpg"
import img1 from "../Assets/parntership.jpg";
import "../Styles/Service.css";

const Partnership = () => {
  return (
    <>
   <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
       
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Partnership Firm?</h2>
        <p className='mt-4'>A firm or company established between two or more partners with the objective of earning profit is called as a Partnership Firm. It is not mandatory to register a partnership firm but there are other advantages if a partnership firm is registered. Partnership action is a legal document that is created to form a partnership firm.</p>
       <p className='mt-4'> Indian Partnership Act No. 1932 is the governing the law which regulates the partnership firms in India. As per the Indian Partnership Act, the share between 2 persons who have approved to divide the profits of a business accepted by all. The upper limit of members in a partnership firm is 10 for banking business and 20 for other businesses to enter into a partnership firm.</p>
       <p className='mt-4'>A partnership firm would be dissolved if the number of partners reduces below 2 in case of natural death, incapable or resignation of a partner.</p>
       <p className='mt-4 mb-9'>A partnership firm cannot be a debtor or creditor and cannot own a property/asset. The asset, debit or credit of a partnership firm is actually for the partners in the eyes of laws. The way in which profits or losses are to be shared amongst partners must be openly mentioned in the partnership action to avoid any misunderstanding in the future. Each and every partner can carry on business on behalf of others.</p>
       </div>
       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full">
            <h1 className="font-bold text-2xl p-4">How can I register my partnership firm?</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Visit shoolin consultancy Website. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Contact the legal expert team and provide basic information about Partnership by filing up the details.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Get this document printed on Stamp paper of specific value and get it registered with the help of our legal expert team.</span>
                       
                    </li>
                </ul>
              
                
            </div>
        
            
            
    </div>
    </>
  )
}

export default Partnership