import React from 'react';
import imgbanner from "../Assets/foodpagebanner.jpg"
import img1 from "../Assets/foodimage.jpg";
import "../Styles/Service.css";

const FssaiReg = () => {
  return (
    <>
     <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
      
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is FSSAI?</h2>
        <p className='mt-4'>FSSAI full form is the Food Safety and Standards Authority of India.
</p>
       <p className='mt-4'>FSSAI License or FSSAI Registration is compulsory before starting any food business in India. FSSAI Registration is compulsory for all food-related businesses such as importers, exporters, home-based food businesses, manufacturers, and traders, etc. </p>
       <p className='mt-4'>Who are involved in food business industries must obtain a 14-digit registration Number or called Food license registration number which should be printed on food packages or Displayed in the building. </p>
       <p className='mt-4 mb-6'>This 14 digit FSSAI license number gives information about the producer’s permit or enrollment elements.    </p>
       <h2 className='font-bold text-2xl mt-6 mb-6'>Meaning of FSSAI</h2>
       <p className='mt-4 mb-6'>FSSAI is an authority to offer a food license to every food business in India. FSSAI makes sure that the food business runs with a proper license and a quality check. The food business should follow the FSSAI rules and guidelines. FSSAI is entirely liable for setting the standard principles and controls for the welfare of food businesses in India.</p>
       <h2 className='font-bold text-2xl mt-6 mb-6'>What is FSSAI Registration (food license)?</h2>
       <p className='mt-4 mb-6'>FSSAI is an independent department recognized under the Ministry of Health & Family Welfare, Government of India. The FSSAI has been established under the Food Safety and Standards Act, No. 2006 which is a consolidate law related to food safety and regulation in India. It is an association that monitors and rules the food business in India. FSSAI License is liable for protecting public health through food safety supervision.</p>
       </div>
       <img className='company' src={img1}  alt="phot"></img>
       <div className='fssaibanner mt-9'>
        <h4 className='font-bold text-3xl text-white p-9'>Types of FSSAI license</h4>
        <ul className="leading-8 p-2 list-none list-inside text-white px-11">
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  BASIC FSSAI REGISTRATION.
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  STATE FSSAI LICENSE.
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4 before:mr-2 before:content-['✔']">
   CENTRAL FSSAI LICENSE.
  </li>
 
</ul>
       </div>
      
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits of FSSAI Registration for your business?</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">It creates consumer awareness. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">You can use FSSAI logo, which can build goodwill among the customers.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Maintenance of good manufacture, storage, distribution, sale and import of food.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">To facilitate food safety.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Business expansion.</span>
                       
                    </li>
                </ul>
            </div>
   
   
    </div>
    </>
  )
}

export default FssaiReg