import React from 'react';
import imgbanner from "../Assets/importbanner.jpg"
import img1 from "../Assets/importimage.jpg";
import "../Styles/Service.css";

const ImportExport = () => {
  return (
    <>
  <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
        
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is IEC?</h2>
        <p className='mt-4'>If you are in the business of Importing and Exporting of goods in India, it mandatory to have 10 digit Import Export Code(IEC). Import Export Code also is abbreviated as IEC.
</p>
       <p className='mt-4 mb-4'> Import Export Code(IEC) is required for all persons or enterprises involved in the Import or Export of goods. Import Export Code is issued by the Directorate General of Foreign Trade (DGFT), Government of India. Import Export Code has Lifetime Validity.</p>
      
       </div>

       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Features of Import and Export Code (IEC)</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">It is compulsory for every importer and exporter to register under IEC </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">The revenue cannot be claimed without this code.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">IEC code has lifetime validity; you don’t have to renew it again and again.</span>
                       
                    </li>
                </ul>
            </div>
            
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits of IEC</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">You can sell your products on international platforms. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Several benefits from DGFT and, customs with the IEC registration.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">No need for any renewal IEC code is successful for the lifetime.   </span>
                       
                    </li>
                </ul>
            </div>
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Needs of IEC</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">The importer needs IEC License for custom clearance.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">The Exporters need IEC License for export subsidy.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold"> For Food Licensing IE Code is Required.   </span>
                       
                    </li>
                </ul>
            </div>
   
 
    </div>
    </>
  )
}

export default ImportExport