import React from 'react';
import imgbanner from "../Assets/musicbanner.jpg"
import img1 from "../Assets/musicimage.jpg";
import "../Styles/Service.css";

const MusicLicense = () => {
  return (
    <>
   <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
       
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Music Licensing?</h2>
        <p className='mt-4'>Music Licensing is nothing but taking authorization for the use of Musical composition(work). For this, the Licensor and Licensee should enter into a License Agreement to use that music.
</p>
       <p className='mt-4'> The License Agreement also needs its conditions of use and specifies the time period for using that music and lastly, Payment made to the legal owner for the use of Property. This procedure is governed by the Copyright Act, No. 1967.</p>
       <h2 className='font-bold text-2xl mt-6 mb-6'>How does this work?</h2>
       <p className='mb-4'>To acquire a license, the person has to apply for the music performance license, where we have to give all the relevant information needed. After that, a quotation will be shared with the client. Once, we get the music license digitally then, we can easily play the music in every form or method.</p>
       </div>

       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits of Music License</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Music license helps the artists to use this as a platform for showing their skills.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Music license helps in the fame of the song or event of the artist.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">There will be no chance of stealing the creation of an artist that the creation of the artist will be copyrighted after obtaining this license.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">The third-party can easily download it. thus, it is a good earning source.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">An artist can get a right to perform their talent through public events or other broadcasting events.  </span>
                       
                    </li>
                </ul>
            </div>

    </div>
    </>
  )
}

export default MusicLicense