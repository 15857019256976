import React from 'react';
import imgbanner from "../Assets/Drugbanner.jpg"
import img1 from "../Assets/drugimage.jpg";
import "../Styles/Service.css";

const DrugLicense = () => {
  return (
    <>
    <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
    
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is a DRUG?</h2>
        <p className='mt-4'>All medicines which are used for internal or external use of human or animals called drugs, and all substances planned to be used for treatment, etc. or prevention of any kind of disease or disorder in human beings or animals, including preparations, are known as drugs.
For releasing new drugs in India. international pharmaceutical companies these days have a better legal foundation. And these structural changes coincide with India’s growing demand for new classes of drugs as well as their increasing capability to afford international prices.
</p>
       <p className='mt-4 mb-4'> However recently developed overseas drugs are now increasingly able to take advantage of India’s drug in growth, the Indian medical authoritarian system has become more complex, and new drugs can take a year or more to be approved for marketing.</p>
       <img className='company' src={img1}  alt="phot"></img>
       <h2 className='font-bold text-2xl mt-6 mb-6'>What is Drug Regulation?</h2>
       <p>Drug regulation means to control something so that it functions properly Drug Regulatory Affair is a moderately new profession which is developed by the government to protect public health, by controlling the safety of public health products including veterinary medicines, medical devices, agrochemicals, cosmetics, and complementary medicines, etc.</p>

       <h2 className='font-bold text-2xl mt-6 mb-6'>Drug Regulatory System In India?</h2>
       <p>Legal validation to Regulate Importing the Manufactured products & Selling of Drugs and Cosmetics Act, 1940.</p>
       <p className='mt-4'> Drugs, and Cosmetics Rules, Act 1945 made under the Act Drug Price Control Order (DPCO), 1995.</p>
       </div>

      
        
           

    </div>
    </>
  )
}

export default DrugLicense