import React from 'react';
import imgbanner from "../Assets/ngobanner.jpg"
import img1 from "../Assets/ngoimage.jpg";
import "../Styles/Service.css"

const NgoFirm = () => {
  return (
    <>
<img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
       
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is an NGO?</h2>
        <div className='leading-8'>
        <p className='mt-4 font-semibold'>Purpose of NGO – Section 8 Company</p>
       <p className='mt-2'> The core function of establishing a company as a Section 8 company is to promote non-profit objectives such as trade, commerce, arts, charity, education, etc. The profits of the company if any are applied towards promoting the objectives of the company and are not distributed as equal share to its shareholders.</p>
       <p className='mt-4'>The minimum of 2 directors is required if the Section 8 company is to be integrated as a private limited company, and a minimum of three directors in case of incorporation as a public limited company. The most number of members is 200 in the case of a private limited company, whereas for a public limited company, there is no such limit.Also, there is no requirement of minimum paid-up capital in the case of a Section 8 company.</p>
      
       </div>
       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Laws in India applicable to an NGO.</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Trust under the Indian Trusts Act, 1882. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Society under Societies Registration Act 1860.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Section 8 Company under Companies Act, 2013..</span>
                       
                    </li>
                </ul>
               
                
            </div>
        
            
    </div>
    </>
  )
}

export default NgoFirm