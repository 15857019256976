import React, { useEffect, lazy } from "react";

// router
import { Routes, Route, Navigate } from "react-router-dom";
import BlogList from "./Blog/BlogList";
import CompanyRegistration from "./ServicePages/CompanyRegistration.jsx";
import Proprietorship from "./ServicePages/Proprietorship.jsx";
import LLPRegistration from "./ServicePages/LLPRegistration.jsx";
import Oneperson from "./ServicePages/Oneperson.jsx";
import PrivateLimited from "./ServicePages/PrivateLimited.jsx";
import Partnership from "./ServicePages/Partnership.jsx";
import NgoFirm from "./ServicePages/NgoFirm.jsx";
import GstRegsitrationnew from "./ServicePages/GstRegsitrationnew.jsx";
import ProvidentFund from "./ServicePages/ProvidentFund.jsx";
import ESI from "./ServicePages/ESI.jsx"
import TradeLicense from "./ServicePages/TradeLicense.jsx";
import FssaiReg from "./ServicePages/FssaiReg.jsx";
import ShopReg from "./ServicePages/ShopReg.jsx";
import ImportExport from "./ServicePages/ImportExport.jsx";
import MusicLicense from "./ServicePages/MusicLicense.jsx";
import DrugLicense from "./ServicePages/DrugLicense.jsx";
import Udyod from "./ServicePages/Udyod.jsx";
import ITReturns from "./ServicePages/ITReturns.jsx";
import AccountingService from "./ServicePages/AccountingService.jsx";
import Contactus1 from "./MainPages/Contactus1.jsx";
import Bbbmpblog from "./Blog/Bbbmpblog.jsx";
import Shopblog from "./Blog/Shopblog.jsx";
import Rentalagree from "./Blog/Rentalagree.jsx";
import Property from "./Blog/Property.jsx";
import Leaseagree from "./Blog/Leaseagree.jsx";
import Partnershipblog from "./Blog/Partnershipblog.jsx";

// MainPages
const Home = lazy(() => import("./MainPages/Home"));
const RentalAgreement  = lazy(() => import("./ServicePages/RentalAgreement.jsx"));
const FoodLicense  = lazy(() => import( "./ServicePages/FoodLicense.jsx"));
const GSTRegistration = lazy(() => import( "./ServicePages/GSTRegistration.jsx"));
const MarriageRegistration  = lazy(() => import( "./ServicePages/MarriageRegistration.jsx"));
const CriminalCase  = lazy(() => import( "./ServicePages/CriminalCase.jsx"));
const PropertyVerification  = lazy(() => import("./ServicePages/PropertyVerification.jsx"));
const DivorceMediation  = lazy(() => import("./ServicePages/DivorceMediation.jsx"));
const BlogHome = lazy(() => import( "./Blog/BlogHome.js"));
const BlogDetails  = lazy(() => import("./Blog/BlogDetails.js"));
const AboutusPage  = lazy(() => import( "./MainPages/AboutUsPage.jsx"));
const NotFound  = lazy(() => import( "./MainPages/NotFoundPage.jsx"));
const Businessneeds  = lazy(() => import( "./ServicePages/Businessneeds.jsx"));


// components
const Navbar  = lazy(() => import( "./SharedComponents/Navbar.jsx"));
const Footer  = lazy(() => import( "./SharedComponents/Footer.jsx"));
const ContactPage  = lazy(() => import( "./MainPages/ContactPage.jsx"));
const Whatsapp  = lazy(() => import( "./SharedComponents/Whatsapp.jsx"));

//data
const blogPosts  = lazy(() => import( "./Data/BlogData.jsx"));
const PricingPlans  = lazy(() => import( "./MainPages/PricingPlans.jsx"));
const ResourcePage  = lazy(() => import( "./MainPages/ResourcePage.jsx"));
const TestimonialPage  = lazy(() => import( "./MainPages/TestimonialPage.jsx"));
const TopBar  = lazy(() => import( "./SharedComponents/TopBar.jsx"));
const Term  = lazy(() => import( "./SharedComponents/Term.jsx"));

const App = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling behavior
    });

    return () => {};
  }, []);

  return (
    <>
      <Whatsapp />
      <TopBar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<AboutusPage />} />
        <Route path="/rentalagreementbangalore" element={<RentalAgreement />} />
        <Route path="/foodlicensebangalore" element={<FoodLicense />} />
        <Route path="/gstregistrationbangalore" element={<GSTRegistration />} />
        <Route
          path="/marriageregistrationbangalore"
          element={<MarriageRegistration />}
        />
        <Route path="/Businessneeds" element={<Businessneeds />} />
        <Route path="/CriminalCase" element={<CriminalCase />} />
        <Route
          path="/PropertyVerification"
          element={<PropertyVerification />}
        />
        <Route path="/CompanyRegistration" element={<CompanyRegistration/>}/>
        <Route path="/Proprietorship" element={<Proprietorship/>}/>
        <Route path="/LLPRegistration" element={<LLPRegistration/>}/>
        <Route path="/Oneperson" element={<Oneperson/>}/>
        <Route path="PrivateLimited" element={<PrivateLimited/>}/>
        <Route path="Partnership" element={<Partnership/>}/>
        <Route path="NgoFirm" element={<NgoFirm/>}/>
        <Route path="GstRegsitrationnew" element={<GstRegsitrationnew/>}/>
        <Route path="ProvidentFund" element={<ProvidentFund/>}/>
        <Route path="ESI" element={<ESI/>}/>
        <Route path="TradeLicense" element={<TradeLicense/>}/>
        <Route path="FssaiReg" element={<FssaiReg/>}/>
        <Route path="ShopReg" element={<ShopReg/>}/>
        <Route path="ImportExport" element={<ImportExport/>}/>
        <Route path="MusicLicense" element={<MusicLicense/>}/>
        <Route path="DrugLicense" element={<DrugLicense/>}/>
        <Route path="Udyod" element={<Udyod/>}/>
        <Route path="ITReturns" element={<ITReturns/>}/>
        <Route path="AccountingServices" element={<AccountingService/>}/>
      








        <Route path="/DivorceMediation" element={<DivorceMediation />} />
        <Route path="/contact-us1" element={<Contactus1/>}/>
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/pricing-plans" element={<PricingPlans />} />
        <Route path="/resources" element={<ResourcePage />} />
        <Route path="/testimonial" element={<TestimonialPage />} />
        <Route exact path="/blog" element={<BlogHome posts={blogPosts} />} />
        <Route path="/blog/:id" element={<BlogDetails posts={blogPosts} />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/Term" element={<Term />} />
        <Route path="/BlogList" element={<BlogList/>}/>
        <Route path="/Bbbmpblog" element={<Bbbmpblog/>}/>
        <Route path="/shopblog" element={<Shopblog/>}/>
        <Route path="/rentalagree" element={<Rentalagree/>}/>
        <Route path="/property" element={<Property/>}/>
        <Route path="/leaseagree" element={<Leaseagree/>}/>
        <Route path="partnershipblog" element={<Partnershipblog/>}/>
      </Routes>
      <Footer />
    </>
  );
};

export default App;
