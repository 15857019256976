import React from 'react';
import imgbanner from "../Assets/shoppagebanner.jpg"
import img1 from "../Assets/shopimage.jpg";
import "../Styles/Service.css";

const ShopReg = () => {
  return (
    <>
    <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
        
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Shop and Establishment Registration?</h2>
        <p className='mt-4'>The term shop and establishment mean all operations that want to operate as a shop in a particular state of India. If you are doing business in Karnataka and want to work in such a capacity in India, this is something necessary that you must understand. For most businesses in Karnataka; it is compulsory to be registered under this Shops and Establishment Registration Act. This can be called the first step that you would take towards the business.
</p>
       <p className='mt-4'> Generally, each state has its own shop and establishment-related rules and regulations. This also means that the rules of such an act would be different from one state to another. So before going to acquire a shop and establishment license certificate in Bangalore; you need to know and understand the overall process about it; because you’re a businessman and doing business in this place to uplift your business.</p>
       <p className='mt-4 mb-4'>Shops and establishment registration is compulsory for all companies, businesses, legal entities, and proprietors those who are doing commerce in the state or who’ve already been set up their establishments but have not registered yet. Please take the certificate and be free from the penalty.</p>
       <img className='company' src={img1}  alt="phot"></img>
       <h2 className='font-bold text-2xl mt-6 mb-6'>Who is Eligible to Register Under This Act?</h2>
       <p>As Karnataka Shops & Commercial Establishments Act No.1962 provides a clear definition for the terms establishment, shop, and commercial establishment and it states, these types should acquire the registration certificate. The term “Establishment” means any shop establishment.As per the Act the term “Shop” means any premises where business or trade is being done.</p>
       <h2 className='font-bold text-2xl mt-6 mb-6'>Validity and Renewal</h2>
       <p>The registration certificate is valid upto 5 years. And the license has to be renewed before its expiry date.</p>
     
       </div>
     
      
        
         
   
   
    </div>
    </>
  )
}

export default ShopReg