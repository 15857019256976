import React from 'react';
import imgbanner from "../Assets/privatebanner.jpg"
import img1 from "../Assets/privateimage.jpg";
import "../Styles/Service.css"

const PrivateLimited = () => {
  return (
    <>
<img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
      
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is a Private Limited Company (PVT LTD)?</h2>
        <p className='mt-4'>A Private Limited Company (PVT LTD) is a privately held small business operation. The legal responsibility members of a private limited company are limited to the number of shares held by that member. A private limited company is ruled by Companies Act, No.2013. The lower limit of shareholders required to start a private limited company is two while the upper limit of members is 200 in accordance with the Companies Act, No. 2013.</p>
       <p className='mt-4'>If a private limited company faces a financial threat, its shareholders are not legally responsible to sell their individual assets/properties i.e. they have limited liability or legal responsibility. There must be a lower limit of two directors and upper limit 15 directors for a private limited company and a director must be 18 years above in age. A foreign citizen can become a director of private limited company India (PVT LTD).</p>
       <p className='mt-4'>It is mandatory to add privately limited (PVT ltd) to a private limited company India. least paid capital amount for a private limited company is Rs. 1 Lakh. A private limited company keeps on existing even in the case of death or economic failure of its Members.</p>
       </div>
       
       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Benefits of Private Limited Company (PVT LTD)</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Limited liabilities. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">The legal operation is separate.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Easy to form.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Funds & Tax Benefits.</span>
                       
                    </li>
                </ul>
               
                
            </div>
        
            
            
    </div>
    </>
  )
}

export default PrivateLimited