import React from 'react';
import imgbanner from "../Assets/itreturnbanner.jpg"
import img1 from "../Assets/itreturnimg.jpg";
import "../Styles/Service.css";

const ITReturns = () => {
  return (
    <>
<img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
        
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is IT Returns?</h2>
        <p className='mt-4'>The Income Tax Return (IT Return) is the process of proclaiming the total income of an individual or a firm/company to the Income Tax Department of India, at the end of each financial year.
</p>
     
       <h2 className='font-bold text-2xl mt-6 mb-6'>What is IT Returns?</h2>
       <p>Virtual CFO is the organization looking to outsource the entire finance and accounts to a single partner. Our team enables faultless delivery of Virtual CFO services and not to have separate labor consultants, tax consultants, CA’s, CS’s and accounting executives. We are here to provide high-quality services at a fraction of the cost incurred to keep an in-house team by adopting a shared service model. We are the best in India in providing Virtual CFO Services. Our quality of delivery in this area, i.e. Virtual CFO services, is better than anyone in the country for Virtual CFO services.</p>
       <p className='mt-4 mb-6'>Our solution is day-to-day bookkeeping responsibilities. We establish smooth day-to-day functioning for the client’s operations team. We provide advice and bring in best practices in finance and accounts for the client. We provide customized and timely Reporting to our clients typically include book-keeping, payroll management, vendor payments, receivables management, taxation, and reporting.</p>
       </div>

       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">The core areas of our focus are</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Finance & Accounts Outsourcing.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Shared services model.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Day-to-day finance advice and taking the ownership.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Finance matters discussion.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Secretarial & Legal Compliance.  </span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">End to End solutions.  </span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Trademark and Copyrights. </span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Drafting & Filing of financial statements.  </span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">End-to End ownership & assistance of Return Filing. </span>
                       
                    </li>
                </ul>
            </div>

    </div>
    </>
  )
}

export default ITReturns