import React from 'react';
import imgbanner from "../Assets/llpbanner.jpg"
import img1 from "../Assets/llpimage.jpg";
import "../Styles/Service.css"

const LLPRegistration = () => {
  return (
    <>
    <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
        
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Limited Liability Partnership (LLP) registration?</h2>
        <p className='mt-4'>Limited Liability Partnership (LLP) Registration as the name indicates defines limited liability to its partners. hence it has the features of both corporation and partnership. One partner is liable for only his own work actions and not the work actions of his partner. LLPs are governed by the Limited Liability Partnership Act(LLP Act), No. 2008.</p>
        <h2 className='font-bold text-2xl mt-4 mb-4'>What is Limited Liability Partnership (LLP) registration?</h2>
        <p className='mb-2'>According to the Ministry of Corporate Affairs(MCA), Limited Liability Partnership (LLP) is an alternative corporate business type that gives the benefits of limited liability of a company and the flexibility of a partnership. As the name indicates, partners in LLP have limited liability.</p>
        <p className='mb-2'>This means that personal assets/resources of the partners cannot be used for paying off the debts of the company.Partners are liable only to the level of their agreed contribution in the LLP. However, the LLP is liable completely to the level of its resources. Since many business executives are opting for this, it has become a very popular type of business in the recent past.</p>
        <p className='mb-2'>There are many numbers of partners in the firm. Each partner is liable for their own acts and not legally responsible for other’s misbehavior issues. In this method, individual partners are protected from the joint liability that is created by another partner’s illegal business decisions or any wrongdoing.</p>
        <p className='mb-2'>In case of change of allies/partners, the LLP will continue its existence; entering into contracts and holding property in its own name. An agreement between the partners and the LLP rules the mutual rights, duties, and responsibilities of the partners.</p>
       
      </div>
      <img className='company' src={img1}  alt="phot"></img>
    
        
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4 mt-4">Benefits of Limited Liability Partnership (LLP)</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Easy formation. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Liability.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Perpetual succession.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Easy transfer-ability of ownership/proprietorship.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Taxation.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">No compulsory audit required.</span>
                       
                    </li>
                </ul>
                
            </div>
        </div>
 
    </>
  )
}

export default LLPRegistration