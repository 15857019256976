import React from 'react';

import imgbanner from "../Assets/contactbanner.png"
import Contactussection1 from '../SharedComponents/Contactussection1';


const Contactus1 = () => {
  return (
    <>
   <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <Contactussection1/>    
    </>
  )
}

export default Contactus1