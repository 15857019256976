import React from 'react'

const Bbbmpblog = () => {
  return (
    <div className="xl:container mx-auto my-6 p-4 bg-gray-100">
      
    <div>
   <h1 className="text-3xl font-bold mt-4 mb-4 text-indigo-700">BBMP TRADE LICENSE</h1>
      
      
    
        <p className='mb-4' >Hi there!, are you excited to launch a new company? Are you worried about how to open it?, What licence is required? and concerned about how to stay legally safe, well, you are at the right place and we have got you covered!</p>
        <p className='mb-4'>A trade licence is one of the most crucial licences to have in order to operate any kind of significant business. In India, trade licences are required for all individuals and in Karnataka, a "BBMP Trade Licence" is required. The Bangalore municipal body corporation, known as the BBMP, grants this BBMP Trade Licence to businesses and industries operating inside its boundaries. It has the authority to close or seal any location in accordance with a scheduled act. The certificate can be renewed when it has been in effect for more than a year.
</p>
<h5 className='mb-2'>Now let's examine each of the BBMP Trade License's components in more detail:</h5>
<ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                        <span className="font-bold"> Industries License : </span> Small, medium and large scale manufacturing industries like e.g., Paper Bags, Candles, Leather goods, etc.    
                    </li>
                    <li className='mb-2'>
                        <span className="font-bold"> Shop License : </span> It could be some of the dangerous trades like Cracker Manufacturer, Sale of Firewood, Salon, Gaming rooms, Laundry etc.    
                    </li>
                    <li className='mb-2'>
                        <span className="font-bold">  Food Establishment License : </span> Food joints, Canteens, Hotels, Restaurants, Sale of Meat and Vegetables, Dairy, Cook room, Skullery, Shopping Centers, Movie Theatres, and 
the like.     
                    </li>
                </ul>
                <h5 className='mb-2'>Cons of not having a BBMP Trade License: </h5>
                <ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                        <span> Fees, penalties and appropriate legal action will be attracted upon violation of terms and conditions of the laws.   </span> 
                    </li>
                    <li className='mb-2'>
                        <span> In case of any business nuisance it may attract cancellation and revoking of the 
license. </span> 
                    </li>
                    
                    </ul>
                    <h5 className='mb-2 font-bold'>The BBMP Trade License: Documents Checklist!  </h5>
                <ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                        <span> Address Proof (Rental Agreement or Electricity Bill)  </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Id Proof (Aadhar Card) </span> 
                    </li>
                    <li className='mb-2'>
                        <span> PAN Card </span> 
                    </li>
                    <li className='mb-2'>
                        <span>  Copy of recent municipality Tax paid receipt  </span> 
                    </li>
                    <li className='mb-2'>
                        <span>Consent letter from property owner</span> 
                    </li>
                    <li className='mb-2'>
                        <span> NOC from the immediate neighbour  </span> 
                    </li>
                    <li className='mb-2'>
                        <span>Miscellaneous </span> 
                    </li>
                    </ul>
    <p className='font-bold'> For more information and also to ensure a hassle free registration, kindly connect to 
'Registration Guru'. We in Registration Guru will provide you with clarity on the 
incorporation process and fulfil all your legal requirements.</p>
    </div>

</div>
  )
}

export default Bbbmpblog