import React from 'react';
// import HeroBanner from "../SharedComponents/HeroBanner";
import img1 from "../Assets/opcimage.jpg";
import "../Styles/Service.css"
import imgbanner from "../Assets/opcbannner.jpg"

const Oneperson = () => {
  return (
    <>
    {/* <HeroBanner  heading="One Person Company (OPC) Registration" /> */}
    <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
       
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is One Person Company Registration?</h2>
        <p className='mt-4'>The Companies Act, No. 2013 launched the concept of One Person Company in India in order to support business executives capable of raising their own business enterprise. This business enterprise allows them to create a single person economic operation. In case of a One Person Company Registration, only one member should be there while registering this Application Form of a company while in the case of a Limited Liability Partnership firm or a Private Limited Company, there should at least be 2 individuals in order for registering the company.</p>
       <p className='mt-4'> A one-person company is a company that contains accurately one member. It is a separate legal operation from its supporter and the supporter has limited liability.</p>
       <p className='mt-4'>The business executives who are capable of starting a business enterprise on their own can make use of a one-person company (OPC) in India.
In a one-person company, there is only one shareholder who is an Indian citizen and Indian resident i.e. staying in India for at least 182 days in the preceding year. Shareholder nominates another person as a nominee in case of death or incapability of the shareholder. One person company was introduced in the companies act No. 2013 to encourage self-employment. You cannot integrate more than One Person Company or be a nominee of more than one (OPC) one-person company. Rules of (OPC) One Person Company, the company does not permit Non-Banking Financials.</p>
     
      </div>
     
      <img className='company' src={img1}  alt="phot"></img>
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Benefits of a One Person Company</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Corporate Body. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Minimum Capital not required.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Seamless/Smooth Procedure for Registration.</span>
                       
                    </li>
                </ul>
               
                
            </div>
        
            
          
</div>
  
    </>
  )
}

export default Oneperson