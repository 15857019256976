import React from 'react'

const Property = () => {
  return (
 <>
 <div className="xl:container mx-auto my-6 p-4 bg-gray-100">
 <h1 className="text-2xl font-bold mt-4 mb-4 text-indigo-700">PROPERTY REGISTRATION </h1>
 <p className='mb-4'>Hello there!, Are you eager to purchase a new property, build a luxury home there, and fill it 
with treasured memories? However, if you want the joy to endure longer, you must tread 
carefully by registering your property to ensure that you're protected under the law. </p>
<p className='mb-4'>Now let's examine each of the Property Registrations components in more detail:
</p>
<h3 className="text-l font-bold mb-2">Property Registration: What Is It?, Where is it done? and Who Should Take It? </h3>
<p className='mb-4'>The Property Registration is the final and comprehensive agreement signed by both parties 
to a property transaction. The legal transfer of property from one person to another 
requires registration at the sub-registrar's office and the payment of certain fees, such as 
stamp duty. And, this process is known as registry of property. </p>
<p className='mb-4'>Under the control of the relevant state governments, property registration is completed at 
the sub-registrar's or sub-registry office. Many property-related documents, including sale 
deeds, gift deeds, partition deeds, release deeds, mortgage deeds, and other legal 
documents pertaining to property transactions, must be registered with the Sub-Registrar's 
Office.</p>
<p className='mb-4'>Any immovable property may be purchased by any Indian resident or non-resident alien 
(NRI) who has been a resident of the nation for a few specified years. One immovable 
property (other than agricultural land, a farm house, or plantation property) may be 
acquired by a resident of outside of India who is the spouse of a non-resident Indian or an 
overseas citizen of India but is not an OCI.
</p>
<h3 className="text-l font-bold mb-2">Property Registration: Pros and Cons ! </h3>
<p>Pros:</p>
<ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                    It is a vital phase in proving ownership legally and assuring title.  
                    </li>
                    <li className='mb-2'>
                    It Helps in safe guarding property rights in India.     
                    </li>
                    <li className='mb-2'>
                    It provides certainty and protection to property owners.     
                    </li>
                    <li className='mb-2'>
                    It ensure a smooth and lawful transaction.    
                    </li>
                    <li className='mb-2'>
                    It ensures conservation of evidence .     
                    </li>
                    <li className='mb-2'>
                    It helps in preventing fraud.     
                    </li>
                </ul>
                <p>Cons:</p>
<ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                    Failure to register the property within 4 months of execution will attract a hefty
penalty of 10 times more than the original registration fee.  
                    </li>
                    <li className='mb-2'>
                    Failure to register a property could put you at a huge risk as it cannot be admitted as 
evidence in the court of law (If the property is not registered) .     
                    </li>
                    <li className='mb-2'>
                    Proving ownership is not possible until your name is mentioned in the government 
records .     
                    </li>
                    <li className='mb-2'>
                    Unregistered properties have no legal standing and, even if the owner is in 
possession of them, they could be taken.    
                    </li>
                    <li className='mb-2'>
                    If the government ever purchase this land for the purpose of developing an infrastructure pr
oject, the owner would not be eligible to receive the compensation that is normally provided
to land/property owners in such circumstances.     
                    </li>
                </ul>

                <h3 className="text-l font-bold mb-2">Property Registration: Types of Property Registration ! </h3>
<p>Type:</p>
<ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                    Sale Deed Registration.
                    </li>
                    <li className='mb-2'>
                    Gift Deed Registration.     
                    </li>
                    <li className='mb-2'>
                    Partition Deed Registration.     
                    </li>
                    <li className='mb-2'>
                    Release Deed Registration.    
                    </li>
                   
                    <li className='mb-2'>
                    Mortgage Deed Registration.     
                    </li>
                </ul>
          
<h3 className="text-l font-bold mb-2">      Property Registration: Documents Checklist !  </h3>

<ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                    Khata .
                    </li>
                    <li className='mb-2'>
                    Sanctioned building plan .     
                    </li>
                    <li className='mb-2'>
                    Tax Paid Receipts .     
                    </li>
                    <li className='mb-2'>
                    Encumbrance certificate .    
                    </li>
                    <li className='mb-2'>
                    RTC- Rights and Tenancy corps.     
                    </li>
                    <li className='mb-2'>
                    Copies of all previous agreements.     
                    </li>
                    <li className='mb-2'>
                    Misscellaneous.     
                    </li>
                </ul>
                <p className='mb-4'>For more information and also to ensure a hassle free registration, kindly connect to 'Registration Guru'. We in Registration Guru will provide you with clarity on the Registratrion and Licensing process and fulfil all your legal requirements.</p>
                <h3 className="text-l font-bold mb-4">Regards<br></br>Pratheeka L Gaonkar <br></br>B M Gaonkar<br></br>
REGISTRATION GURU</h3>
 </div>
 </>
  )
}

export default Property