import React from 'react';
 import imgbanner from "../Assets/providentbanner.jpg"
import img1 from "../Assets/providentimage.jpg";
import "../Styles/Service.css"

const ProvidentFund = () => {
  return (
    <>
<img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
     
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Provident Fund?</h2>
        <p className='mt-4'>A Provident fund is a mandatory, government-managed retirement savings scheme similar to the Social Security Program(SSP). Workers contribute a small amount of their salary and even the company shares an amount on behalf of the worker which can be utilized by the employee for future use.</p>
       <p className='mt-4'>The Government established this scheme in 1952 established for the benefit and welfare of the employees.</p>
       <p className='mt-4'>Employees Provident Fund is one of the main platforms for saving money for all the people who are working as an employee.
Companies that have employee strength of 20 or more are required to be registered with PROVIDENT FUND (PF) Department. In case there is any delay or issue, some penalties will be charged on the same.</p>
       
       </div>
       <img className='company' src={img1}  alt="phot"></img>
    
       <div className="flex flex-col xl:flex-row xl:gap-20 flex-wrap p-9">
    <div className="w-full xl:w-full flex flex-col justify-around">
      
        
        <div className="flex flex-wrap">
        
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Benefits of Provident Fund</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Beneficial for insurance. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">PF can lead to a pension in the future.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">It can be helpful for various occasions and situation needs.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Interest on the saved amount.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">After 10 years, you are eligible to get the lifelong pension as per the Employees Pension Scheme 1995.</span>
                       
                    </li>
                </ul>
                
            </div>
        
            
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Eligibility for having Provident Fund</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">The one whose pay is more than 15000 per month.</span>
                        
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">An employee is eligible for membership from the day of joining.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Contact Shoolin consultancy Website.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Provide the required documents to us.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">An acknowledgment will be provided by our end.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Finally, the document will be delivered to you at the end of the process.</span>
                       
                    </li>
                </ul>
            </div>
            
          
        </div>
    </div>
</div>
    </div>
    </>
  )
}

export default ProvidentFund