import React from 'react';
import imgbanner from "../Assets/Accountingbanner.jpg"
import img1 from "../Assets/accountingimg.jpg";
import "../Styles/Service.css";

const AccountingService = () => {
  return (
    <>
  <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
     
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Accounting?</h2>
        <p className='mt-4'>The name “Accounting” suggests the meaning that is “maintaining financial accounts”. “Book-keeping and Accounting can be divided into several fields including financial, managing general accounting, Professional, chartered accountant, auditing, tax and cost accounting, etc”.
</p>
<p className='mt-4'>Each and every business needs to maintain its financial account maintenance every year. It’s mandatory, all the organizations are required to present their records to the Income Tax (IT) Department. Keeping up the books in-house completely is a costly issue, and online measures are a much more easy and better way.</p>
<p className='mt-4 mb-4'>Our accountants can handle advanced tasks and they use advanced software to complete their work and maintain accounting records. This process can be made easy with the help of Shoolin Consultancy accounting service, with us you can handle your accounts with ease.</p>
     
      
       </div>

       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Why Online Accounting?</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">It is more complex to maintain books in-house positively.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold"> Completing it with online measures is a much more easy and better way.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Our accountants’ team can hold more advanced tasks and they use software to complete their work.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">This process can be made easy with the help of  Shoolin Consultancy accounting service, with us you can handle your accounts in the best way.</span>
                       
                    </li>
                </ul>
              
            </div>
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits Of Accounting?</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold"> Cost-efficient.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Less time-consuming.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">User-friendly.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Statistics Works as financial data proof.</span>
                       
                    </li>
                </ul>
              
            </div>

    </div>
    </>
  )
}

export default AccountingService