import React from 'react';
import imgbanner from "../Assets/proprietorbanner.jpg" 
import img1 from "../Assets/proprietorimage.jpg";
import "../Styles/Service.css"

const Proprietorship = () => {
  return (
    <>
<img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
        
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is Proprietorship Registration?</h2>
        <p className='mt-4'>When a single person runs a company/business then such type of business is called a proprietary business, and the holder of the business is called the proprietor. Proprietorship is the most ordinary figure of the business which is used in India. You can start and operate the business with minimum authoritarian conformity. However, there is no fully-fledged way accessible to register the proprietorship by the Government.</p>
       <p className='mt-4'>Tax registration and other business registration is the right way to prove the existence of your proprietary business.</p>
       <p className='font-bold mt-4 mb-4'>The following registrations can be used to show the existence of your proprietary Business.</p>
       </div>
       <img className='company' src={img1}  alt="phot"></img>
       <div className='Probanner mt-10 '>
       
        <ul className="leading-8 p-6 list-none list-inside text-white px-11">
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  PROFESSIONAL TAX REGISTRATION.
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  GST REGISTRATION.
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4 before:mr-2 before:content-['✔']">
  SHOP AND ESTABLISHMENT REGISTRATION.
  </li>
</ul>
       </div>
       <p className='mt-4 mb-2'>The holder of a proprietorship business usually signs contracts in his or her own name, because the proprietorship business has no separate part identity under the law. The proprietor owner will typically have customers checks in the owner’s name, even if the business uses a pretended name. Proprietor owners can, and frequently do, mix personal and business property and funds/Shares, something that partnerships, LLCs and corporations cannot do. Proprietorships regularly have their bank accounts in the name of the owner. Proprietors need not notice the procedure such as voting and meetings associated with the more complex business. Proprietorships can bring lawsuits (and can be used) using the name of the single proprietor owner.</p>
     
 
      
        
       
        
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Benefits of Proprietorship</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Limited and Unlimited Liability. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Succession.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Compliance.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Conversion.</span>
                       
                    </li>
                </ul>
                
                
            </div>
        
            
           
            
          
        </div>
 

   
    </>
  )
}

export default Proprietorship