import React from 'react';
import imgbanner from "../Assets/gstpagebanner.jpg"
import img1 from "../Assets/gstimage.jpg";
import "../Styles/Service.css";

const GstRegsitrationnew = () => {
  return (
    <>
    <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
  
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is GST?</h2>
        <p className='mt-4'>The goods and services tax (GST) is a value-added tax imposed on all goods and services sold for domestic expenditure. It is a purpose-based tax on the utilization of goods and services. It is proposed to be imposed at all stages starting from manufacture to end utilization with credit of taxes paid at previous stages available as set forth. In some products, only value addition will be taxed and the burden of tax is to be borne by the final consumer.</p>
       <p className='mt-4'> The GST came into existence from the year 2017 through the implementation of 100 and First Amendment of the Constitution of India by the Indian Government. The tax replaced existing Central and State Government taxes.</p>
       <p className='mt-4'>The tax rates, policy, and regulations are governed by the GST Council which consists of the finance minister of central and all the states.</p>
       <p className='mt-4 mb-9'>GST is meant to replace a violent or uncontrollable sliding movement of indirect taxes with a combined tax and is therefore predicted to re-shape the country’s 2.4 trillion dollar economy.</p>


       <p className='mt-4 mb-9'>GST tax rates vary from 0 – 28 percentage depending on the type of services or Nature of Goods depending upon Your business is selling.</p>
       <p className='mt-4 mb-9'>The GST is paid by consumers, but it is hand over to the Indian government by businesses selling goods and services. In effect, GST provides income for the government.</p>
       <p className='mt-4 mb-9'>Get Your GST Registration done by professionals. Now your GST Registration is easier than ever. Get a completely managed GST registration service in Bangalore and enjoy post-registration support for filing & tax credit services as well from experts from Shoolin Consultancy. a completely managed GST registration service online and enjoy post-registration support for filing & tax credit services as well.</p>
       </div>
      
       <img className='company' src={img1}  alt="phot"></img>
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits of GST Registration</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">You can legally collect taxes from your customers. Business Becomes 100% tax Compliant.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">GST certificate can be used as one of the documents for Business Account.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">To start payment gateways and use mobile wallets GST number is used and its importance.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">You can also apply for GSTN.</span>
                       
                    </li>
                </ul>
               
                
            </div>
        
            
      
    </div>
    </>
  )
}

export default GstRegsitrationnew