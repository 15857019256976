import React from 'react';
import imgbanner from "../Assets/esibanner.jpg"
import img1 from "../Assets/esiimage.jpg";
import "../Styles/Service.css"

const ESI = () => {
  return (
    <>
  <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
      
        <div className='leading-8'></div>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is the ESI Scheme?</h2>
       
       <p className='mt-4 mb-9'>ESI is a complete Social Security Scheme(SSS) designed to provide social protection to the employees against sickness, disablement, and death due to employment injury and other events/issues. The fund is self-financing where employer and employee contributions on a monthly basis and the amount can be utilized towards compensating expenses on the health of the employee and his family members.</p>
       <img className='company' src={img1}  alt="phot"></img>
        
       <div className='ESIbanner mt-9'>
        <h4 className='font-bold text-2xl text-white p-9'>CONTRIBUTION RATE TO THE ESI FUND</h4>
        <ul className="leading-8 p-2 list-none list-inside text-white px-11">
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  AN ELIGIBLE EMPLOYEE SHOULD CONTRIBUTE TO A TOTAL OF 1.75% OF THEIR SALARY (BASIC+ALLOWANCES).
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  REGISTERED EMPLOYERS SHOULD DEPOSIT 4.75% OF SALARY PAID(BASIC+ALLOWANCES) TO ESI CORPUS EVERY MONTH.
  </li>
 
</ul>
       </div>
      
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits of company registration/incorporation</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">An employee acquires full of medical care during the period of his incapability to relieve/cure his health.</span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">An employee acquires financial assistance to compensate for the loss in work due to sickness, maternity and employment injury, etc.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Medical care benefits to family members.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">In case of the death of an insured employee due to employment injury, the spouse, widowed mother and children are entitled to Dependent’s benefit.</span>
                       
                    </li>
                </ul>
               
                
            </div>
        
        
    </div>
    </>
  )
}

export default ESI