import React from 'react';
import imgbanner from "../Assets/companyregbanner.jpg"

import img1 from "../Assets/companyreg.jpg";
import "../Styles/Service.css"

const CompanyRegistration = () => {
  return (
    <>
    <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto mb-6 p-4 '>
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-4 mb-6'>Company registration or incorporation means?</h2>
        <p className='mt-4'>Incorporation is the origination of a new company (a corporation being a legal operation that is efficiently documented as a person under the law). The corporation/company may be a business, a non-profit organization, a startup, a micro small or medium scale business.</p>
       <p className='mt-4'> Ministry of Corporate Affairs(MCA) regulates corporate relationships in India through the Companies Act, No.1956, No 2013 and other related Acts, Bills, and Rules. Ministry of Corporate Affairs(MCA) also protects investors and offers many important services to stakeholders.</p>
       <p className='mt-4'>The Ministry is primarily concerned with the administration of the Companies Act No. 2013, the Companies Act No.1956, the Limited Liability Partnership Act No. 2008 & other related Acts and rules & regulations framed there-under mainly for regulating the performance of the corporate sector in accordance with the law.</p>
       <p className='mt-4 mb-9'>Start your new company registration in Bangalore with the most affordable pricing, dedicated account management support at Shoolin Consultancy. A team of expert CA, CS, and Lawyers help you step-by-step to incorporate your company.</p>
       </div>
       <img className='company' src={img1}  alt="phot"></img>
       <div className='companybanner mt-12'>
        <h4 className='font-bold text-3xl text-white p-9'>Companies could be classified as follows:</h4>
        <ul className="leading-8 p-2 list-none list-inside text-white px-11">
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  PRIVATE LIMITED COMPANY
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  ONE PERSON COMPANY
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4 before:mr-2 before:content-['✔']">
  LIMITED COMPANY
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4 before:mr-2 before:content-['✔']">
  SECTION 8 COMPANY
  </li>
</ul>
       </div>
       <div className="flex flex-col xl:flex-row xl:gap-20 flex-wrap p-9">
    <div className="w-full xl:w-full flex flex-col justify-around">
      
        
        <div className="flex flex-wrap">
        
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Benefits of company registration/incorporation</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Legal identification/Recognition to the business. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Tax Benefits.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Get investment/ share/funding for your company business.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Your Liability/ responsibility as an individual is limited.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Increase in Brand Image and superior in Trust.</span>
                       
                    </li>
                </ul>
                
            </div>
        
            
            <div className="w-full xl:w-1/2">
            <h1 className="font-bold text-2xl p-4">Why choose Us</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Legal Experts.</span>
                        
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Reasonable Prices.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Best On-time Delivery.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Security& reliability.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Simple Procedure & Customer Satisfaction.</span>
                       
                    </li>
                </ul>
            </div>
            
          
        </div>
    </div>
</div>
    </div>
    </>
  )
}

export default CompanyRegistration