import React from 'react';
import imgbanner from "../Assets/BBMPbanner.jpg"
import img1 from "../Assets/tradeimage.jpg";
import "../Styles/Service.css";

const TradeLicense = () => {
  return (
    <>
  <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
      
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is a Trade License?</h2>
        <p className='mt-4'>Trade license is the way to legalize specific businesses from a particular region. It is issued by the State Government. A trade license is an authorization/permission to carry on a specific trade or business at the building for which it has been issued.
</p>
       <p className='mt-4'> Trade License has introduced in India since 40 years ago.
It does not assure any kind of assets ownership and not even allowed to do any other business rather than for which it is issued. It is made necessary for an entity/individual to register his new business and get an Online trade license for it.</p>
       <p className='mt-4'>The major purpose of the trade license is to reduce the people from making their residential area as their business location. The application must be made within 30 days of commencement of business.</p>
       <p className='mt-4 mb-9'>BBMP trade license means Bruhat Bengaluru Mahanagara Palike(BBMP) trade license.</p>
       </div>
       <img className='company' src={img1}  alt="phot"></img>
       <div className='tradebanner mt-9'>
        <h4 className='font-bold text-3xl text-white p-9'>Eligibility for a Trade license</h4>
        <ul className="leading-8 p-2 list-none list-inside text-white px-11">
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  THE CANDIDATE MUST HAVE CROSSED THE AGE OF 18 YEARS.
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4  before:mr-2 before:content-['✔']">
  THE APPLICANT MUST NOT HAVE ANY CRIMINAL RECORDS.
  </li>
  <li className="relative before:inline-block before:w-4 before:h-4 before:mr-2 before:content-['✔']">
  BUSINESS MUST BE LEGALLY PERMISSIBLE.
  </li>
 
</ul>
       </div>
      
        
            <div className="w-full ">
            <h1 className="font-bold text-2xl p-4">Benefits of Trade License Registration</h1>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">If you have a business license or a trade license you will be legally responsible to enjoy the rights of it. </span>
                        
                    </li>
                </ul>
             
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">It helps to grow the prestige of the firm and attract the customers and investors in it.</span>
                       
                    </li>
                </ul>
                <ul className="p-4 list-disc list-inside">
                    <li>
                        <span className="font-bold">Get investment/ share/funding for your company business.</span>
                       
                    </li>
                </ul>
            </div>
   
    <div>
        <h2 className='mt-4 mb-2 font-bold text-2xl'>
        Which businesses need a Trade License Certificate?
        </h2>
        <p className='font-bold'>All Food Serving Premises :</p>
        <p className='mb-2'>Hotels, Restaurants, Canteens, Malls, Theaters, Clubs, bakeries and confectionery shops, Snack shops, Food stalls, Retailers and traders of goods, Daily need stores.</p>
        <p className='font-bold'>Service Trades :</p>
        <p className='mb-2'>Cybercafes, timber shops, all small-medium scale industries.</p>
        <p className='font-bold'>Manufacturing Units/ Industries:</p>
        <p className='mb-2'>Manufacturing and processing small and medium and large scale industries.</p>
    </div>
    </div>
    </>
  )
}

export default TradeLicense