import React from 'react';
import imgbanner from "../Assets/udyodbanner.jpg"
import img1 from "../Assets/msmeimg.jpg";
import "../Styles/Service.css";

const Udyod = () => {
  return (
    <>
 <img className='servicebanner sm:mt-2 mt-4 lg:mt-0' src={imgbanner} alt='banner'></img>
    <div className='xl:container mx-auto my-6 p-4 '>
    
        <div className='leading-8'>
        <h2 className='font-bold text-2xl mt-6 mb-6'>What is MSME Registration?</h2>
        <p className='mt-4'>MSME registration is a kind of registration for Micro, Small and Medium Enterprises under MSMED that is (Micro, Small and Medium Enterprises Development) Act. This helps small businesses to grow and gain various benefits from the government.
</p>
       <p className='mt-4'> MSME Stands for Micro small and medium enterprises are known as MSME.</p>
       <p className='mt-4 mb-4'>MSME stands for Micro, Small, Medium Enterprises and any business enterprise that falls under any of these three classifications. MSME is the foundation of any economy and is a glide of financial development, developing fair advancement for all. To help the advance MSME enterprises by the Government of India through different sponsorships schemes. And have the advantages under the MSMED.
Under the MSMED act, every MSME needs to get registered whether the business service-related for manufacturing. MSME registration is a useful extract control tax sponsorships, capital appropriations, and others.</p>
<img className='company' src={img1}  alt="phot"></img>
       <h2 className='font-bold text-2xl mt-6 mb-6'>Benefits of MSME Registration</h2>
       <p className='font-bold'>Tax Benefits:</p><span> According to your business, you may enjoy an excise benefit scheme as well as direct taxes for the initial years of your business.</span>
       <p className='font-bold'>Easy Clearance of Pending Payments: </p><span>MSME helps in easy clearance for any pending payment.</span>
       <p className='font-bold'>50% discount on trademark and Patent Fees:</p><span> It helps to reduce the pricing of trademark and patent registration.</span>
       <p className='font-bold'>Eligible for Loan Scheme:</p><span> MSME registration helps you to be eligible for the loan scheme.</span>
       <p className='font-bold'>Apply Government Tenders Easily:</p><span> There is a sales tax exemption in most states.  </span>

       
       </div>

      
        
           

    </div>
    </>
  )
}

export default Udyod