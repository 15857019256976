import React from 'react'

const Shopblog = () => {
  return (
    <div className="xl:container mx-auto my-6 p-4 bg-gray-100">
      
    <div>
   <h1 className="text-3xl font-bold mt-4 mb-4 text-indigo-700">SHOP AND ESTABLISHMENTS</h1>
      
    
    
        <p className='mb-4' >Do you often feel that your 9-5 job is horrid and lousy? Do you frequently feel that at work, your finest efforts are not being shown? Is the constant conflict at work causing you to become emotionally and mentally exhausted and drained? Well then, here you are in this right place where you can show case the best of you by successfully building and running your own enterprise and business entity. </p>
        <p className='mb-4'>Going ahead, are you confused about how to start your commercial establishment? who 
should you approach to obtain the Certificate? what are the documents required? Basically, 
are you concerned about the entire process !. Don't worry, we have got you covered!. Prior 
to that, let's get down to the aspects of the Shop and Establishment Act, as Follows: </p>
      
      <h3 className="text-xl font-bold mb-4">The Shop and Commercial Establishments Act: What Is It and Who Should Take It?</h3>

        <p className='mb-4' >This particular act is regulated by the department of Labour. And, every establishment needs to be registered within 30 days of commencing the business. The document will be valid for 5 Years and Renewal should be done 15 days before the expiry date. According to the Shop and Establishments Act, a commercial establishment is a place where any trade, profession, business or anything related to work is undertaken. Most businesses in India and their workforce come under the Shops and Establishments Act. And, every business owner in India must be aware of the significance of the Shop and Commercial Establishment License and must and should avail it for the legal, professional, smooth and seamless operation of the business. This act regulates numerous aspects of work-life, Stores, Trade, banking, insurance, stock, including wages, working hours, holidays, earned leaves, child labour, women's rights, and more.</p>

    
      <h3 className="text-xl font-bold mb-4">The Shop and Commercial Establishments Act: Pros and Cons ! </h3>
     
        <p className='mb-2'>Pros:</p>
        <ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                        <span>  It provides legal compliance with state laws and regulations.   </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Receives Employee Benefits  </span> 
                    </li>
                    <li className='mb-2'>
                        <span>Protection of Workers Rights  </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Funding and Bank Loans Provision for government Schemes and Subsidies  </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Access to government benefits</span> 
                    </li>
                    <li className='mb-2'>
                        <span> Receive Legal Protection   </span> 
                    </li>
                   
                    </ul>
                    <p className='mb-2'>Cons: </p>
        <ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                        <span>  Unable to receive Current account and company PAN.    </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Failure to obtain and renew will lead to hefty penalty and cancel the license.  </span> 
                    </li>
                    </ul>
                    <h3 className="text-xl font-bold mb-4">The Shop and Commercial Establishments Act: Documents Checklist !   </h3>
                    <ul className="p-4 list-disc list-inside ">
                    <li className='mb-2'>
                        <span>  Address Proof (Rental Agreement or Electricity Bill)</span> 
                    </li>
                    <li className='mb-2'>
                        <span> Id Proof   </span> 
                    </li>
                    <li className='mb-2'>
                        <span> PAN Card  </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Payment Challan </span> 
                    </li>
                    <li className='mb-2'>
                        <span> Authorization Letter</span> 
                    </li>
                    <li className='mb-2'>
                        <span> Declaration Form for Non Appointment of Child Labour   </span> 
                    </li>
                    <li className='mb-2'>
                        <span>  Miscellaneous  </span> 
                    </li>
                   
                    </ul>
                    <p className='font-bold'> For more information and to avail service kindly connect to 'Registration Guru'.</p>

  
    </div>

</div>
  )
}

export default Shopblog